import Icon from '@/components/icons';
import { Buffer } from 'buffer';
import Cookies from 'js-cookie';
import _ from 'lodash';
import moment from 'moment';
import { removeAuthorization } from 'utils/axios.config';
import { SAMPLE_ROUTE } from '../constants';

export const normalizeSrc = (src) => {
  return src[0] === '/' ? src.slice(1) : src;
};

export const imageloader = ({ src, width, quality = 60 }) => {
  const params = [`width=${width}`];
  params.push(`f=auto`);
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(',');
  return `https://www.havehalalwilltravel.com/cdn-cgi/image/${paramsString}/${normalizeSrc(
    src
  )}`;
};

export const signOut = () => {
  if (typeof window !== 'undefined') {
    removeAuthorization();
    clearAllDataStorage();

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }
};

export const clearAllDataStorage = () => {
  localStorage.clear();

  Cookies.remove('user_id');
  Cookies.remove('logged_user_name');
  Cookies.remove('logged_user_email');
  Cookies.remove('logged_user_lastname');
  Cookies.remove('logged_user_firstname');
  Cookies.remove('username');
  Cookies.remove('logged_user_photo');
  Cookies.remove('qid');
  Cookies.remove('token');
};

export const hashCodeUserProfile = (hasCode) => {
  return JSON.parse(Buffer.from(hasCode, 'base64').toString('utf-8'));
};

export const compressHashCodeUserProfile = () => {
  if (Cookies.get('user_id')) {
    const model = {
      logged_user_email:
        Cookies.get('logged_user_email') ||
        localStorage.getItem('logged_user_email'),
      logged_user_firstname:
        Cookies.get('logged_user_firstname') &&
        Cookies.get('logged_user_firstname'),
      logged_user_name:
        Cookies.get('logged_user_name') && Cookies.get('logged_user_name'),
      logged_user_lastname:
        Cookies.get('logged_user_lastname') &&
        Cookies.get('logged_user_lastname'),
      logged_user_id: Cookies.get('user_id') && Cookies.get('user_id'),
      logged_user_photo:
        Cookies.get('logged_user_photo') && Cookies.get('logged_user_photo'),
      username: Cookies.get('username') && Cookies.get('username'),
    };

    const q = Buffer.from(JSON.stringify(model)).toString('base64');

    return `?_q=${q}`;
  } else return '';
};

export const refreshAdsSlot = (slot) => {
  const interval = setInterval(function () {
    const googletag = window?.googletag || { cmd: [] };

    googletag.pubads().refresh([slot]);
    clearInterval(interval);
  }, 30000);
};

export const getLocaleUrl = (locale = 'en', slug = '') => {
  const enLocale = 'en';
  const baseUrl = process.env.NEXT_PUBLIC_LANDING_URL;
  const localeUrl =
    locale === enLocale
      ? `${baseUrl}/${slug}`
      : `${baseUrl}/${locale}${slug ? '/' + slug : ''}`;

  return localeUrl;
};

export const getLocalListingCompany = (locale = 'en', slug = '') => {
  const enLocale = 'en';
  const baseUrl = process.env.NEXT_PUBLIC_LANDING_URL;
  const localeUrl =
    locale === enLocale
      ? `${baseUrl}/${slug}`
      : `${baseUrl}/${locale}${slug ? '/' + slug : ''}`;
  return localeUrl;
};

export const getListingUrl = (category = 'category', categoryLink, slug) => {
  if (categoryLink) return `${category}/${categoryLink}/${slug}`;
  else return `${category}/sub-${category}/${slug}`;
};

export const getListingUrlWithLocale = (
  locale,
  category,
  country,
  city,
  listingSlug
) => {
  const formattedCountry =
    country?.toLowerCase()?.split(' ')?.join('-') || SAMPLE_ROUTE.ALL_COUNTRY;
  const formattedCity =
    city?.toLowerCase()?.split(' ')?.join('-') || SAMPLE_ROUTE.ALL_CITY;
  return `${getLocaleUrl(
    locale,
    `${category}/${formattedCountry}/${formattedCity}/${listingSlug}`
  )}`;
};

export const getListingCompanyUrl = (locale, listingSlug, category, menu) => {
  return `${getLocalListingCompany(
    locale,
    `company-user/${category}/${listingSlug}/${menu}`
  )}`;
};

export const CATEGORY_SLUGS = {
  BUY: 'buy',
  EAT: 'eat',
  SEE_AND_DO: 'see-and-do',
  TRANSPORT: 'transport',
  STAY: 'stay',
  OTHER: 'Other',
};

export const handleCountStars = (rating = 0) => {
  const MAX_RATING = 5;
  const unFillStar = <Icon icon="star" color="#FDB022" />;
  const filledStar = <Icon icon="red-star" color="#FDB022" />;
  let ratingStar = [];

  for (let i = 1; i <= MAX_RATING; i++) {
    const currentRating = Math.round(rating);
    if (i <= currentRating) ratingStar.push(filledStar);
    else ratingStar.push(unFillStar);
  }
  return ratingStar;
};

export const handleGetMonthsFromNowToPrevYear = () => {
  let currentDate = new Date();
  const givenDateTime = new Date(
    moment().set({
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear() - 1,
    })
  );

  let createdDate = new Date(givenDateTime);
  createdDate.setDate(1);

  let dateAndYearList = [
    {
      label: createdDate.toLocaleString('en', {
        month: 'long',
        year: 'numeric',
      }),
      value: moment(createdDate),
    },
  ];

  while (createdDate.setMonth(createdDate.getMonth() + 1) < currentDate) {
    dateAndYearList.unshift({
      label: createdDate.toLocaleString('en', {
        month: 'long',
        year: 'numeric',
      }),
      value: moment(createdDate),
    });
  }

  return dateAndYearList;
};

export const handleFormatText = (text = '') => {
  const textSplit = _.split(text, '-');
  const textCapitalize = _.map(
    textSplit,
    (txt) => txt.charAt(0).toUpperCase() + _.slice(txt, 1).join('')
  ).join(' ');

  return textCapitalize;
};

export const checkImageSize = (imageUrl = '') => {
  try {
    if (!imageUrl) return {};

    let width = 160 / 1.2;
    let height = 160 / 1.2;

    const defaultVerticalWidth = 160;
    const defaultVerticalHeight = 160;
    const defaultHorizontalWidth = 250;
    const defaultHorizontalHeight = 150;

    const image = new Image();
    image.src = imageUrl;

    image.onload = function () {
      // landscape
      if (image.width > image.height) {
        console.log('landscape');
        width = defaultHorizontalWidth / 1.2;
        height = defaultHorizontalHeight / 1.2;
        // portrait
      } else if (image.width < image.height) {
        width = defaultHorizontalHeight / 1.2;
        height = defaultHorizontalWidth / 1.2;
        console.log('portrait');
      } else {
        // square
        width = defaultVerticalWidth / 1.2;
        height = defaultVerticalHeight / 1.2;
        console.log('square');
      }
    };

    image.onerror = function () {
      console.log('error');
      width = defaultHorizontalWidth / 1.2;
      height = defaultHorizontalHeight / 1.2;
    };

    return { width, height };
  } catch (error) {
    console.log(error);
  }
};
